import React from "react";
import "./PageHeader.scss";

const PageHeader = (props) => {
  return (
    <div className="page-header-container__header">
      <h1 className="page-header-container__header-primary">
        {props.data.pageHeaderTitle}
      </h1>
      <h2
        className={`page-header-container__header-secondary ${
          props.isLowercaseOnMobile ? "mobile-lowercase" : ""
        }`}
      >
        {props.data.pageHeaderDescription.internal.content}
      </h2>
    </div>
  );
};

export default PageHeader;
