import React, { useState, useEffect } from "react";
import ProjectItem from "./ProjectItem";
import PageHeader from "../PageHeader/PageHeader";
import "./ProjectsContainer.scss";

//lighbox gallery
import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/captions.css";


const ProjectContainer = (props) => {
  const [visibleProjects, setVisibleProjects] = useState(8);
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState('');

  const openLightbox = (index) => {
    setCurrentSlideIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const headerData = {
    ...props.data[0],
  };
  const projectsArray = props.data[1].containerItems;

  const handleLoadMore = () => {
    setVisibleProjects((prev) => {
      const next = prev + 8;
      return next < projectsArray.length ? next : projectsArray.length;
    });
  };

  const columns = isMobile ? 1 : 2;
  const rows = isMobile
    ? visibleProjects
    : Math.ceil((visibleProjects / 8) * 21);
  const itemHeight = isMobile ? "auto" : "120px";
  const gridGap = isMobile ? "0" : "25px";
  const gridTemplateRows = `repeat(${rows}, ${itemHeight})`;
  const gridTemplateColumns = `repeat(${columns}, 1fr)`;

  const gridStyles = {
    display: "grid",
    gridTemplateColumns,
    gridTemplateRows,
    gap: `${gridGap}`,
  };

  return (
    <>
      <PageHeader data={headerData} />
      <div className="projects-container" style={gridStyles}>
        {projectsArray.slice(0, visibleProjects).map((project, i) => (
          <ProjectItem key={i} isItemTall={i % 5 === 0} data={project} projectIndex={i} openLightbox={openLightbox}/>
        ))}
      </div>

      {lightboxOpen && (
        <Lightbox
          slides={projectsArray.map((project, i) => ({
            src: `https:${project.projectImage.file.url}`,
            title: project.projectNameOfTheWindow,
          }))}
          index={currentSlideIndex}
          close={closeLightbox}
          open={lightboxOpen}
          plugins={[
            Fullscreen,
            Slideshow,
            Thumbnails,
            Zoom,
            Counter,
            Captions,
          ]}
        />
      )}

      <button
        onClick={handleLoadMore}
        className="btn-primary"
        style={{
          display: `${
            visibleProjects === projectsArray.length ? "none" : "block"
          }`,
        }}
      >
        {props.data[2].buttonText}
      </button>
    </>
  );
};

export default ProjectContainer;

export const useMediaQuery = (query) => {
  const mediaMatch =
    typeof window !== "undefined" ? window.matchMedia(query) : null;

  const [matches, setMatches] = useState(
    mediaMatch ? mediaMatch.matches : false
  );

  useEffect(() => {
    if (!mediaMatch) return;
    const handler = (e) => setMatches(e.matches);
    mediaMatch.addEventListener("change", handler);
    return () => mediaMatch.removeEventListener("change", handler);
  }, [mediaMatch]);

  return matches;
};
