import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout/Layout";
import ProjectsContainer from "../../components/ProjectsContainer/ProjectsContainer";
import SocialMedia from "../../components/SocialMedia/SocialMedia";


// Style Imports


export default function Gallery({ data }) {

  const [fixed, setFixed] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        if (window.scrollY >= 400 && window.scrollY <= 3300) {
          setFixed(true);
        } else {
          setFixed(false);
        }
      });
    }
  }, []);


  const [error, setError] = useState(null);
  useEffect(() => {
    try {
      if (!data) {
        throw new Error("Data not available");
      }
    } catch (error) {
      setError(error);
    }
  }, [data]);

  if (error) {
    return <span>{error.map((err) => err.message).join(",")}</span>;
  }


  return (
    <Layout
      pageTitle={"Gallery"}
      navData={
        data.allContentfulWebPages.edges[0].node.pages[3].pageLayout.navigation
      }
      footerData={
        data.allContentfulWebPages.edges[0].node.pages[0].pageLayout.footer
      }
    > 
    <div className={ fixed ? "social-buttons fixed " : "social-buttons"} style={{top: !fixed ?'500px':""}} >
    <SocialMedia socialMediaData={
          data.allContentfulWebPages.edges[0].node.pages[1].pageLayout
            .layoutMain[1].buttonsContainer
        } />
        </div>
      <ProjectsContainer
        data={
          data.allContentfulWebPages.edges[0].node.pages[3].pageLayout
            .layoutMain
        }
      />
    </Layout>
  );
}

export const galleryPageData = graphql`
  query GalleryPageQuery {
    allContentfulWebPages {
      edges {
        node {
          pages {
            pageLayout {
              navigation {
                navigationPages {
                  pageTitle
                  pageSlug
                  pageLayout {
                    layoutMain {
                      ... on ContentfulContainer {
                        containerItems {
                          ... on ContentfulCategoryTeaser {
                            category {
                              categoryInnerTitle
                              categorySlug
                              categoryProducts {
                                product {
                                  productInnerTitle
                                  productSlug
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                navigationImages {
                  file {
                    url
                  }
                  description
                }
                navigationMediaIcons {
                  socialMediaIcon {
                    file {
                      url
                    }
                    description
                  }
                  socialMediaTitle
                  socialMediaLink
                }
              }
              layoutMain {
                ... on ContentfulPageHeader {
                  pageHeaderTitle
                  pageHeaderDescription {
                    internal {
                      content
                    }
                  }
                }
                ... on ContentfulContainer {
                  containerItems {
                    ... on ContentfulProject {
                      projectName
                      projectText
                      projectNameOfTheWindow
                      projectYear
                      projectImage {
                        description
                        file {
                          url
                        }
                      }
                      projectProduct {
                        productSlug
                        productCategory {
                          categorySlug
                        }
                      }
                      projectName
                    }
                  }
                }
                ... on ContentfulButton {
                  buttonText
                }
                ... on ContentfulButtonsContainer {
                  buttonsContainer {
                    socialMediaLink
                    socialMediaTitle
                  }
                }
              }
              footer {
                subscribeText
                subscribeDescription
                footerButtonText
                blockTitleFollowUs
                socialMediaLogosFooter {
                  socialMediaIcon {
                    file {
                      url
                    }
                  }
                  socialMediaLink
                }
                followUsDescription
                blockTitleAddress
                blockInfoAddress
                blockTitleCallUs
                blockInfoCallUs
                blockTitleWriteUs
                blockInfoWriteUs
                blockTitleExplore
                footerPages {
                  pageTitle
                  pageSlug
                }
                copyrightText
                googleMapsAddress {
                  googleMapsAddress
                }
              }
            }
          }
        }
      }
    }
  }
`;
