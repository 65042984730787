import React from "react";
import "./ProjectItem.scss";

const ProjectItem = (props) => {
  return (
    <div
      className={`project-gallery-item ${
        props.isItemTall ? "grid-row-span-9" : "grid-row-span-4"
      }`}
      onClick={() => props.openLightbox(props.projectIndex)}
    >
      <img
        src={`https:${props.data.projectImage.file.url}`}
        alt={props.data.projectImage.description}
        loading="lazy"
        className="image"
      />
      <div className="project-info">
        <h1 className="product-name">{props.data.projectName}</h1>
          <div className="window-and-year-container">
            <h2 className="project-name">
              <span className="project-text">
                {props.data.projectText} /&nbsp;
              </span>
              <span className="window-name">
                {props.data.projectNameOfTheWindow}
              </span>
            </h2>
            <h3 className="year-of-completion">{props.data.projectYear}</h3>
          </div>
      </div>
    </div>
  );
};

export default ProjectItem;
